import { Module } from 'vuex';
import { IMenuItem } from '@/interfaces/IMenuItem';
import { IMenuItemDetail } from '@/interfaces/IMenuItemDetail';

type StateMenuItem = {
  menuItemList: IMenuItem[];
  newMenuItem: IMenuItem;
  menuItemDetailList: IMenuItemDetail[];
  menuItemDetailNeedCreateList: IMenuItemDetail[];
  currentContentType: string;
  menuItemSelectedState: IMenuItem;
};

export enum MutationsMenuItem {
  SET_MENU_ITEM_LIST = 'SET_MENU_ITEM_LIST',
  SET_NEW_MENU_ITEM = 'SET_NEW_MENU_ITEM'
}
export enum ActionContentType {
  setMenuItemList = 'setMenuItemList',
  setNewMenuItem = 'setNewMenuItem',
  setMenuItemDetailList = 'setMenuItemDetailList',
  setMenuItemDetailNeedCreateList = 'setMenuItemDetailNeedCreateList',
  addMenuItemDetailIntoNeedCreateList = 'addMenuItemDetailIntoNeedCreateList',
  addMenuItemDetailList = 'addMenuItemDetailList',
  setCurrentContentType = 'setCurrentContentType',
  setMenuItemSelectedState = 'setMenuItemSelectedState'
}
export const ModuleMenuItem: Module<StateMenuItem, any> = {
  state: {
    menuItemList: [],
    newMenuItem: {} as IMenuItem,
    menuItemDetailList: [] as IMenuItemDetail[],
    menuItemDetailNeedCreateList: [] as IMenuItemDetail[],
    currentContentType: '',
    menuItemSelectedState: {} as IMenuItem
  },
  mutations: {
    [ActionContentType.setMenuItemList](state: StateMenuItem, payload: IMenuItem[]) {
      state.menuItemList = payload;
    },
    [ActionContentType.setNewMenuItem](state: StateMenuItem, payload: IMenuItem) {
      state.newMenuItem = payload;
    },
    [ActionContentType.setMenuItemDetailList](state: StateMenuItem, payload: IMenuItemDetail[]) {
      state.menuItemDetailList = payload;
    },
    [ActionContentType.setMenuItemDetailNeedCreateList](state: StateMenuItem, payload: IMenuItemDetail[]) {
      state.menuItemDetailNeedCreateList = payload;
    },
    [ActionContentType.addMenuItemDetailIntoNeedCreateList](state: StateMenuItem, payload: IMenuItemDetail) {
      state.menuItemDetailNeedCreateList.push(payload);
    },
    [ActionContentType.addMenuItemDetailList](state: StateMenuItem, payload: IMenuItemDetail) {
      state.menuItemDetailList.push(payload);
    },
    [ActionContentType.setCurrentContentType](state: StateMenuItem, payload: string) {
      state.currentContentType = payload;
    },
    [ActionContentType.setMenuItemSelectedState](state: StateMenuItem, payload: IMenuItem) {
      state.menuItemSelectedState = payload;
    }
  },
  actions: {
    async [ActionContentType.setMenuItemList]({ commit, state }, payload: IMenuItem[]) {
      commit(ActionContentType.setMenuItemList, payload);
    },
    async [ActionContentType.setNewMenuItem]({ commit, state }, payload: IMenuItem) {
      commit(ActionContentType.setNewMenuItem, payload);
    },
    async [ActionContentType.setMenuItemDetailList]({ commit, state }, payload: IMenuItemDetail[]) {
      commit(ActionContentType.setMenuItemDetailList, payload);
    },
    async [ActionContentType.setMenuItemDetailNeedCreateList]({ commit, state }, payload: IMenuItemDetail[]) {
      commit(ActionContentType.setMenuItemDetailNeedCreateList, payload);
    },
    async [ActionContentType.addMenuItemDetailIntoNeedCreateList]({ commit, state }, payload: IMenuItemDetail[]) {
      commit(ActionContentType.addMenuItemDetailIntoNeedCreateList, payload);
    },
    async [ActionContentType.addMenuItemDetailList]({ commit, state }, payload: IMenuItemDetail[]) {
      commit(ActionContentType.addMenuItemDetailList, payload);
    },
    async [ActionContentType.setCurrentContentType]({ commit, state }, payload: string) {
      commit(ActionContentType.setCurrentContentType, payload);
    },
    async [ActionContentType.setMenuItemSelectedState]({ commit, state }, payload: string) {
      commit(ActionContentType.setMenuItemSelectedState, payload);
    }
  },
  getters: {
    menuItemList: (state: any) => state.menuItemList,
    newMenuItem: (state: any) => state.newMenuItem,
    menuItemDetailList: (state: any) => state.menuItemDetailList,
    menuItemDetailNeedCreateList: (state: any) => state.menuItemDetailNeedCreateList,
    currentContentType: (state: any) => state.currentContentType,
    menuItemSelectedState: (state: any) => state.menuItemSelectedState
  }
};
