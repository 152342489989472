
import { useStore } from 'vuex';
import Navbar from '@/components/navbar.vue';
import Menu from '@/components/menu/Menu.vue';
import Login from '@/views/auth/Login.vue';
import { computed, defineComponent, onBeforeMount, reactive, watch } from 'vue';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import { useRoute, useRouter } from 'vue-router';
import { isEmpty } from 'lodash';
import { getUserInfo } from './services/api/user';
import { IUser } from './interfaces/IUser';

export default defineComponent({
  name: 'App',
  components: { Login, Menu, Navbar, Breadcrumb },
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.auth.isLoggedIn);
    const route = useRoute();
    const router = useRouter();
    const trustedRoute = ['login', 'page-403', 'not-found'];
    const isShowBreadcrumb = computed(() => {
      return !trustedRoute.includes(route.name as any);
    });
    const setCurrentUser = (payload: IUser) => store.dispatch('setCurrentUser', payload);
    const role = computed(() => store.getters.role);
    const currentUser = computed(() => store.getters.currentUser);
    // const isActiveMenu = computed(() => {
    //   return !isEmpty(currentUser.value) && role.value === 'canon_admin';
    // });

    onBeforeMount(async () => {
      store.commit('initStore');
      // if (isEmpty(role.value)) {
      //   let response = await getUserInfo();
      //   setCurrentUser(response);
      // }
    });

    // watch(
    //   role,
    //   (val: any, oldVal: any) => {
    //     console.log('val', val);
    //     console.log('oldVals', oldVal);
    //     if (val && val !== 'canon_admin') {
    //       router.push({ name: 'page-403' });
    //     }
    //   },
    //   { immediate: true }
    // );
    return {
      store,
      isLoggedIn,
      isShowBreadcrumb
      // isActiveMenu
    };
  }
});
