import $axios from '@/helpers/axios';

import { IFormLogin } from '@/interfaces/IFormLogin';

export const login = async (form: IFormLogin) => {
  let url = '/token/';
  try {
    let response = await $axios.post(url, form);
    return response.data;
  } catch (err) {
    console.log('login failed', err);
    throw err;
  }
};
