<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24.683" height="24" viewBox="0 0 24.683 24">
    <g id="Icon_feather-layers" data-name="Icon feather-layers" transform="translate(1.342 1)">
      <path
        id="Path_2392"
        data-name="Path 2392"
        d="M14,3,3,8.5,14,14,25,8.5Z"
        transform="translate(-3 -3)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_2393"
        data-name="Path 2393"
        d="M3,25.5,14,31l11-5.5"
        transform="translate(-3 -9)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_2394"
        data-name="Path 2394"
        d="M3,18l11,5.5L25,18"
        transform="translate(-3 -7)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconGalleryRoom',
  props: {
    color: {
      type: String,
      default: '#666'
    }
  }
};
</script>

<style lang="less" scoped></style>
