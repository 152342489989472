import $axios from '@/helpers/axios';

export const getHotspotMenuItemContentByID = async (hotspotID: string | any) => {
  let url = `/admin/gallery_room/hotspot/content/${hotspotID}/menu-item/`;
  try {
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getListContentOfHotspotByID = async (hotspotID: string | any) => {
  let url = `/admin/gallery_room/hotspot/${hotspotID}/content/`;
  try {
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getHotspotContentDetailByID = async (hotspotID: string | any) => {
  let url = `/admin/gallery_room/hotspot/content/${hotspotID}/`;
  try {
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateHotspotContent = async (payload: any) => {
  let url = `/admin/gallery_room/hotspot/content/${payload.id}/`;
  try {
    let response = await $axios.put(url, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateFlagPublishHotspotContent = async (payload: any) => {
  let url = `/admin/gallery_room/hotspot/content/${payload.id}/`;
  let data = { published: payload.published };
  try {
    let response = await $axios.patch(url, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteHotspotContentById = async (id: string) => {
  let url = `/admin/gallery_room/hotspot/content/${id}/`;
  try {
    let response = await $axios.delete(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const createNewHotspotContent = async (hotspotId: string | any, payload: any) => {
  let url = `/admin/gallery_room/hotspot/${hotspotId}/content/`;
  try {
    let response = await $axios.post(url, payload);
    return response.data;
  } catch (err) {
    throw err;
  }
};
