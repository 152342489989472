import $axios from '@/helpers/axios';

export const getMenuItemInfo = async (id: any) => {
  let url = `/admin/gallery_room/menu-item/${id}/`;
  try {
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getListMenuItemDetail = async (id: any) => {
  let url = `/admin/gallery_room/menu-item/${id}/detail/`;
  try {
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const uploadImageOrVideoMenuItemDetail = async (id: any, payload: any) => {
  let url = `/admin/gallery_room/menu-item/${id}/detail/`;
  try {
    let response = await $axios.post(url, payload);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateMenuItem = async (payload: any) => {
  let url = `/admin/gallery_room/menu-item/${payload.id}/`;
  try {
    let response = await $axios.put(url, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateMenuItemDetail = async (payload: any) => {
  let url = `/admin/gallery_room/menu-item-detail/${payload.id}/`;
  try {
    let response = await $axios.patch(url, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteMenuItemDetail = async (id: string | number) => {
  let url = `/admin/gallery_room/menu-item-detail/${id}/`;
  try {
    let response = await $axios.delete(url);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteMenuItem = async (id: string | number) => {
  let url = `/admin/gallery_room/menu-item/${id}/`;
  try {
    let response = await $axios.delete(url);
    return response;
  } catch (err) {
    throw err;
  }
};

export const createNewMenuItem = async (hotspotId: string | any, payload: any) => {
  let url = `/admin/gallery_room/hotspot/content/${hotspotId}/menu-item/`;
  try {
    let response = await $axios.post(url, payload);
    return response.data;
  } catch (err) {
    throw err;
  }
};
