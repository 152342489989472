<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
    <g id="Icon_feather-lock" data-name="Icon feather-lock" transform="translate(-3.5 -2)">
      <path
        id="Path_2446"
        data-name="Path 2446"
        d="M6.056,16.5H16.944A1.578,1.578,0,0,1,18.5,18.1v5.6a1.578,1.578,0,0,1-1.556,1.6H6.056A1.578,1.578,0,0,1,4.5,23.7V18.1a1.578,1.578,0,0,1,1.556-1.6Z"
        transform="translate(0 -6.3)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_2447"
        data-name="Path 2447"
        d="M10.5,10.2V7a3.89,3.89,0,1,1,7.778,0v3.2"
        transform="translate(-2.889 0)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconLock',
  props: {
    color: {
      type: String,
      default: '#666'
    }
  }
};
</script>

<style lang="less" scoped></style>
