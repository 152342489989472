
import IconInfo from '@/components/common/icons/IconInfo.vue';
import { useStore } from '@/store';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'navbar',
  components: { IconInfo },
  setup() {
    const store = useStore();

    const logout = () => {
      store.commit('logout');
    };

    return {
      store,
      logout
    };
  },
  methods: {}
});
