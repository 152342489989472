<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
    <path
      id="Path_2198"
      data-name="Path 2198"
      d="M179.757,4504l6,6-6,6"
      transform="translate(-178.342 -4502.585)"
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ArrowIcon',
  props: {
    color: {
      type: String,
      default: '#666'
    }
  }
});
</script>

<style lang="less" scoped></style>
