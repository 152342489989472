import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bc944c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "breadcrumb" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbItem = _resolveComponent("BreadcrumbItem")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("ol", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crumbs, (crumb, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "breadcrum--item"
            }, [
              _createVNode(_component_BreadcrumbItem, {
                disabled: index === _ctx.crumbs.length - 1,
                meta: crumb.meta,
                name: crumb.name
              }, null, 8, ["disabled", "meta", "name"])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}