<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Icon_feather-settings" data-name="Icon feather-settings" transform="translate(-0.5 -0.5)">
      <path
        id="Path_2395"
        data-name="Path 2395"
        d="M19.5,16.5a3,3,0,1,1-3-3A3,3,0,0,1,19.5,16.5Z"
        transform="translate(-4 -4)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_2396"
        data-name="Path 2396"
        d="M19.9,15.5a1.65,1.65,0,0,0,.33,1.82l.06.06a2,2,0,1,1-2.83,2.83l-.06-.06a1.663,1.663,0,0,0-2.82,1.18v.17a2,2,0,0,1-4,0v-.09A1.65,1.65,0,0,0,9.5,19.9a1.65,1.65,0,0,0-1.82.33l-.06.06a2,2,0,1,1-2.83-2.83l.06-.06a1.663,1.663,0,0,0-1.18-2.82H3.5a2,2,0,0,1,0-4h.09A1.65,1.65,0,0,0,5.1,9.5a1.65,1.65,0,0,0-.33-1.82l-.06-.06A2,2,0,1,1,7.54,4.79l.06.06a1.65,1.65,0,0,0,1.82.33H9.5a1.65,1.65,0,0,0,1-1.51V3.5a2,2,0,0,1,4,0v.09a1.663,1.663,0,0,0,2.82,1.18l.06-.06a2,2,0,1,1,2.83,2.83l-.06.06a1.65,1.65,0,0,0-.33,1.82V9.5a1.65,1.65,0,0,0,1.51,1h.17a2,2,0,0,1,0,4h-.09a1.65,1.65,0,0,0-1.51,1Z"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconSettings',
  props: {
    color: {
      type: String,
      default: '#666'
    }
  }
};
</script>

<style lang="less" scoped></style>
