export let viewRoutes = [
  {
    path: '/',
    name: 'home',

    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
    meta: {
      breadcrumb: {
        name: 'Welcome',
        type: 'home'
      }
    }
  },
  {
    path: '/gallery-rooms/:room_id',
    name: 'hotspot-overview-list',
    component: () => import(/* webpackChunkName: "GalleryRoomOverview" */ '@/views/gallery/GalleryRoomOverview.vue'),
    children: [
      {
        path: 'hotspot/:hotspot_id',
        name: 'hotspot-overview',
        component: () => import(/* webpackChunkName: "HotspotOverview" */ '@/views/hotspot/HotspotOverview.vue'),
        children: [
          {
            path: 'hotspot-content/:hotspot_content_id',
            name: 'hotspot-content-detail',
            component: () => import(/* webChunkName: "HotspotContentDetail" */ '@/views/hotspot/HotspotContentDetail.vue'),
            meta: {
              breadcrumb: {
                name: '',
                type: 'hotspot-content'
              }
            },

            children: [
              {
                path: 'new-menu-item',
                name: 'new-menu-item',
                component: () => import(/* webpackChunkName: "NewMenuItem" */ '@/views/menu-item/NewMenuItem.vue'),
                children: [
                  {
                    path: 'upload/:type_upload',
                    name: 'draft-upload-single-post',
                    component: () => import(/* webpackChunkName: "DraftUploadSingleItem" */ '@/views/menu-item/DraftUploadSingleItem.vue'),
                    meta: {
                      breadcrumb: {
                        name: 'Upload',
                        type: 'upload-single-post'
                      }
                    }
                  },
                  {
                    path: 'edit/:type_upload',
                    name: 'draft-edit-single-post',
                    component: () => import(/* webpackChunkName: "DraftUploadSingleItem" */ '@/views/menu-item/DraftEditSingleItem.vue'),
                    meta: {
                      breadcrumb: {
                        name: 'Upload',
                        type: 'upload-single-post'
                      }
                    }
                  }
                ],
                meta: {
                  breadcrumb: {
                    name: `What's new`,
                    type: 'new-menu-item'
                  }
                }
              },

              {
                path: 'menu-item/:menu_item_id',
                name: 'menu-item-detail',
                component: () => import(/* webpackChunkName: "MenuItemDetail" */ '@/views/menu-item/MenuItemDetail.vue'),
                children: [
                  {
                    path: 'single-item-detail/:single_item_id',
                    name: 'single-item-detail',
                    component: () => import(/* webpackChunkName: "SingleItemMenu" */ '@/views/single-item/SingleItemDetail.vue'),
                    meta: {
                      breadcrumb: {
                        name: '',
                        type: 'menu-item-detail'
                      }
                    },
                  },
                  {
                    path: 'edit/:type_upload',
                    name: 'draft-edit-menu-item-single-post',
                    component: () => import(/* webpackChunkName: "DraftUploadSingleItem" */ '@/views/menu-item/DraftEditMenuItemDetail.vue'),
                    meta: {
                      breadcrumb: {
                        name: 'Upload',
                        type: 'upload-single-post'
                      }
                    }
                  },
                  {
                    path: 'upload/:type_upload',
                    name: 'upload-single-post',
                    component: () => import(/* webpackChunkName: "UploadSingleItem" */ '@/views/menu-item/UploadSingleItem.vue'),
                    meta: {
                      breadcrumb: {
                        name: 'upload',
                        type: 'upload-single-post'
                      }
                    }
                  },

                  {
                    path: 'new',
                    name: 'new-menu-item-detail',
                    component: () => import(/* webpackChunkName: "NewMenuItem" */ '@/views/menu-item/NewMenuItem.vue'),
                    meta: {
                      breadcrumb: {
                        name: `What's new`,
                        type: 'new-menu-item-detail'
                      }
                    }
                  },
                  {
                    path: 'upload/gallery',
                    name: 'upload-menu-item-gallery-type',
                    component: () => import(/* webpackChunkName: "UploadMenuItemGalleryType" */ '@/views/menu-item/upload/UploadMenuItemGalleryType.vue'),
                    meta: {
                      breadcrumb: {
                        name: 'Upload Gallery',
                        type: 'upload-menu-item-detail'
                      }
                    }
                  },
                  {
                    path: 'upload/video',
                    name: 'upload-menu-item-videos-type',
                    component: () => import(/* webpackChunkName: "UploadMenuItemVideoType" */ '@/views/menu-item/upload/UploadMenuItemVideosType.vue'),
                    meta: {
                      breadcrumb: {
                        name: 'Upload Video',
                        type: 'upload-menu-item-detail'
                      }
                    }
                  },
                  {
                    path: 'upload/pdf',
                    name: 'upload-menu-item-pdfs-type',
                    component: () => import(/* webpackChunkName: "UploadMenuItemVideoType" */ '@/views/menu-item/upload/UploadMenuItemPDFsType.vue'),
                    meta: {
                      breadcrumb: {
                        name: 'Upload PDF',
                        type: 'upload-menu-item-detail'
                      }
                    }
                  }
                ],
                meta: {
                  breadcrumb: {
                    name: '',
                    type: 'menu-item'
                  }
                }
              }
            ]
          },
          {
            path: 'new-hotspot-content',
            name: 'new-hotspot-content',
            component: () => import(/* webpackChunkName: "NewHotspotContent" */ '@/views/hotspot/NewHotspotContent.vue'),
            children: [
              {
                path: 'draft-new-menu-item',
                name: 'draft-new-menu-item',
                component: () => import(/* webpackChunkName: "DraftNewMenuItem" */ '@/views/menu-item/DraftNewMenuItem.vue'),
                meta: {
                  breadcrumb: {
                    name: `What's new`,
                    type: 'draft-new-menu-item'
                  }
                }
              }
            ],
            meta: {
              breadcrumb: {
                name: `What's new`,
                type: 'new-hotspot-content'
              }
            }
          }
        ],
        meta: {
          breadcrumb: {
            name: '',
            type: 'hotspot'
          }
        }
      }
    ],
    meta: {
      breadcrumb: {
        name: '',
        type: 'room'
      }
    }
  }
];
