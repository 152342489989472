<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
    <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(1 1)">
      <path
        id="Path_2390"
        data-name="Path 2390"
        d="M4.5,10.7,14.5,3l10,7.7V22.8A2.211,2.211,0,0,1,22.278,25H6.722A2.211,2.211,0,0,1,4.5,22.8Z"
        transform="translate(-4.5 -3)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_2391"
        data-name="Path 2391"
        d="M13.5,29V18h6.667V29"
        transform="translate(-6.833 -7)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconHome',
  props: {
    color: {
      type: String,
      default: '#666'
    }
  }
};
</script>

<style lang="less" scoped></style>
