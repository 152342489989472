import { Module } from 'vuex';
import { IMenuItem } from '@/interfaces/IMenuItem';
import { IHotspotDetail } from '@/interfaces/IHotspotDetail';

type StateHotspotDetail = {
  hotspotDetail: IHotspotDetail;
};

export enum MutationsHotspotDetail {
  SET_LIST_MENU_ITEM = 'SET_LIST_MENU_ITEM',
  SET_NEW_HOTSPOT = 'SET_NEW_HOTSPOT',
  ADD_NEW_MENU_ITEM = 'ADD_NEW_MENU_ITEM',
  UPDATE_IMAGE_URL = 'UPDATE_IMAGE_URL'
}

export enum ActionHotspotDetail {
  setListMenuItem = 'setListMenuItem',
  updateNewHotspotDetail = 'updateNewHotspotDetail',
  addNewMenuItem = 'addNewMenuItem',
  updateImageURL = 'updateImageURL'
}
export const ModuleHotspotDetail: Module<StateHotspotDetail, any> = {
  state: {
    hotspotDetail: {
      title: '',
      template: 0,
      language: 0,
      published: false,
      image: '',
      menuItems: []
    }
  },
  mutations: {
    [ActionHotspotDetail.setListMenuItem](state: StateHotspotDetail, payload: IMenuItem[]) {
      state.hotspotDetail.menuItems = payload;
    },
    [ActionHotspotDetail.updateNewHotspotDetail](state: StateHotspotDetail, payload: any) {
      state.hotspotDetail = { ...state.hotspotDetail, ...payload };
    },
    [ActionHotspotDetail.addNewMenuItem](state: StateHotspotDetail, payload: IMenuItem) {
      state.hotspotDetail.menuItems.push(payload);
    },
    [ActionHotspotDetail.updateImageURL](state: StateHotspotDetail, payload: string) {
      state.hotspotDetail.image = payload;
    }
  },
  actions: {
    async [ActionHotspotDetail.setListMenuItem]({ commit, state }, payload: string) {
      commit(ActionHotspotDetail.setListMenuItem, payload);
    },
    async [ActionHotspotDetail.addNewMenuItem]({ commit, state }, payload: IMenuItem) {
      commit(ActionHotspotDetail.addNewMenuItem, payload);
    },
    async [ActionHotspotDetail.updateNewHotspotDetail]({ commit, state }, payload: IMenuItem) {
      commit(ActionHotspotDetail.updateNewHotspotDetail, payload);
    },
    async [ActionHotspotDetail.updateImageURL]({ commit, state }, payload: IMenuItem) {
      commit(ActionHotspotDetail.updateImageURL, payload);
    }
  },
  getters: {
    hotspotDetail: (state: any) => state.hotspotDetail,
    menuItems: (state: any) => state.hotspotDetail.menuItems
  }
};
