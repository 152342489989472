import { createRouter, createWebHistory } from 'vue-router';
import { settingsRoutes } from '@/router/settings';
import { authRoutes } from '@/router/auth';
import { viewRoutes } from '@/router/views';
import NotFound from '@/views/NotFound.vue';
import Page403 from '@/views/Page403.vue';
import cookies from '@/helpers/cookies';
import { store } from '@/store';

const routes = [
  // View routes
  ...viewRoutes,
  // Settings
  ...settingsRoutes,
  //  Authentication routes
  ...authRoutes,
  // Catch all
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
  { path: '/403', name: 'page-403', component: Page403 }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(to => {
  const refresh = cookies.get('refresh');
  const isLoginPage = to.name === 'login';

  if (!refresh && !isLoginPage) {
    store.state.auth.isLoggedIn = false;
    return { name: 'login' };
  }
  store.dispatch('setBreadcrumb', to.meta.breadcrumb);
});

export default router;
