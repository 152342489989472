
import { useStore } from '@/store';
import { isEmpty } from 'lodash';
import { computed, defineComponent, ref, watch } from 'vue';
import { getGalleryDetailById } from '@/services/api/gallery';
import { getHotspotByID } from '@/services/api/hotspot';
import { getHotspotContentDetailByID } from '@/services/api/hotspot-content';
import { getMenuItemInfo } from '@/services/api/menu-item';

import { useRoute, useRouter } from 'vue-router';
import { getMenuItemDetail } from '@/services/api/hotspot-content-single-item';
export default defineComponent({
  props: {
    meta: {
      type: Object,
      default: {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  setup(props: any, context: any) {
    const route = useRoute();
    const router = useRouter();
    const neededLoadRoom = ref(false);
    const neededLoadHotspot = ref(false);
    const neededLoadHotspotContent = ref(false);
    const neededLoadMenuItem = ref(false);
    const neededLoadMenuItemDetail = ref(false);
    const handleClickLink = () => {
      let params = {} as any;
      if (props.meta.breadcrumb.type === 'room') {
        params.room_id = route.params.room_id;
      } else if (props.meta.breadcrumb.type === 'hotspot') {
        params.room_id = route.params.room_id;
        params.hotspot_id = route.params.hotspot_id;
      } else if (props.meta.breadcrumb.type === 'hotspot-content') {
        params.room_id = route.params.room_id;
        params.hotspot_id = route.params.hotspot_id;
        params.hotspot_content_id = route.params.hotspot_content_id;
      } else if (props.meta.breadcrumb.type === 'menu-item') {
        params.room_id = route.params.room_id;
        params.hotspot_id = route.params.hotspot_id;
        params.hotspot_content_id = route.params.hotspot_content_id;
        params.menu_item_id = route.params.menu_item_id;
      } else if (props.meta.breadcrumb.tpe === 'menu-item-detail') {
        params.room_id = route.params.room_id;
        params.hotspot_id = route.params.hotspot_id;
        params.hotspot_content_id = route.params.hotspot_content_id;
        params.menu_item_id = route.params.menu_item_id;
        params.single_item_id = route.params.single_item_id;
      }
      router.push({ name: props.name, params });
    };
    const nameDisplay = computed(() => {
      if (!isEmpty(props.meta.breadcrumb && props.meta.breadcrumb.name)) {
        return props.meta.breadcrumb.name;
      }
      if (props.meta.breadcrumb.type === 'room') {
        neededLoadRoom.value = true;
        return roomSelected.value && roomSelected.value.name;
      } else if (props.meta.breadcrumb.type === 'hotspot') {
        neededLoadHotspot.value = true;
        return hotSpotSelected.value && hotSpotSelected.value.name;
      } else if (props.meta.breadcrumb.type === 'hotspot-content') {
        neededLoadHotspotContent.value = true;
        return hotSpotContentSelected.value.title;
      } else if (props.meta.breadcrumb.type === 'menu-item') {
        neededLoadMenuItem.value = true;
        return menuItemSelectedState.value.name;
      } else if (props.meta.breadcrumb.type === 'menu-item-detail') {
        neededLoadMenuItemDetail.value = true;
        return menuItemDetailSelected.value.name;
      }
    });
    const store = useStore();
    const roomSelected = computed(() => store.getters.roomSelected);
    const hotSpotSelected = computed(() => store.getters.hotSpotSelected);
    const hotSpotContentSelected = computed(() => store.getters.hotSpotContentSelected);
    const menuItemSelectedState = computed(() => store.getters.menuItemSelectedState);
    const menuItemDetailSelected = computed(() => store.getters.menuItemDetailSelected);
    const setRoomSelected = (payload: any) => store.dispatch('setRoomSelected', payload);
    const setHotspotSelected = (payload: any) => store.dispatch('setHotspotSelected', payload);
    const setHotspotContentSelected = (payload: any) => store.dispatch('setHotspotContentSelected', payload);
    const setMenuItemSelectedState = (payload: any) => store.dispatch('setMenuItemSelectedState', payload);
    const setMenuItemDetailSelected = (payload: any) => store.dispatch('setMenuItemDetailSelected', payload);
    watch(
      neededLoadRoom,
      async (val: any) => {
        if (val && isEmpty(roomSelected.value) && route.params.room_id) {
          let response = await getGalleryDetailById(route.params.room_id);
          setRoomSelected(response);
        }
      },
      { deep: true, immediate: true }
    );

    watch(
      neededLoadHotspot,
      async (val: any) => {
        if (val && isEmpty(hotSpotSelected.value) && route.params.hotspot_id) {
          let response = await getHotspotByID(route.params.hotspot_id);
          setHotspotSelected(response);
        }
      },
      { deep: true, immediate: true }
    );
    watch(
      neededLoadHotspotContent,
      async (val: any) => {
        if (val && isEmpty(hotSpotContentSelected.value) && route.params.hotspot_content_id) {
          let response = await getHotspotContentDetailByID(route.params.hotspot_content_id);
          setHotspotContentSelected(response);
        }
      },
      { deep: true, immediate: true }
    );
    watch(
      neededLoadMenuItem,
      async (val: any) => {
        if (val && isEmpty(menuItemSelectedState.value) && route.params.menu_item_id) {
          let response = await getMenuItemInfo(route.params.menu_item_id);
          setMenuItemSelectedState(response);
        }
      },
      { deep: true, immediate: true }
    );
    watch(
      neededLoadMenuItemDetail,
      async (val: any) => {
        if (val && isEmpty(menuItemDetailSelected.value) && route.params.single_item_id) {
          let response = await getMenuItemDetail(route.params.single_item_id);
          setMenuItemDetailSelected(response);
        }
      },
      { deep: true, immediate: true }
    );
    return {
      nameDisplay,
      roomSelected,
      hotSpotSelected,
      hotSpotContentSelected,
      menuItemSelectedState,
      setRoomSelected,
      setHotspotSelected,
      handleClickLink
    };
  }
});
