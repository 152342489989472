import { Module } from 'vuex';
import { IUser } from '@/interfaces/IUser';

type StateUser = {
  currentUser: IUser;
  users: IUser[];
  selectedUser: IUser;
};

export enum MutationsMenuItem {
  SET_LIST_USER = 'SET_LIST_USER',
  SET_CURRENT_USER = 'SET_CURRENT_USER'
}
export enum ActionUser {
  setListUser = 'setListUser',
  setCurrentUser = 'setCurrentUser',
  addUserIntoList = 'addUserIntoList',
  setSelectedUser = 'setSelectedUser'
}
export const ModuleUser: Module<StateUser, any> = {
  state: {
    currentUser: {} as IUser,
    users: [] as IUser[],
    selectedUser: {} as IUser
  },
  mutations: {
    [ActionUser.setListUser](state: StateUser, payload: IUser[]) {
      state.users = payload;
    },
    [ActionUser.addUserIntoList](state: StateUser, payload: IUser) {
      state.users.push(payload);
    },
    [ActionUser.setCurrentUser](state: StateUser, payload: IUser) {
      state.currentUser = payload;
    },
    [ActionUser.setSelectedUser](state: StateUser, payload: IUser) {
      state.selectedUser = payload;
    }
  },
  actions: {
    async [ActionUser.setListUser]({ commit, state }, payload: IUser[]) {
      commit(ActionUser.setListUser, payload);
    },
    async [ActionUser.addUserIntoList]({ commit, state }, payload: IUser[]) {
      commit(ActionUser.addUserIntoList, payload);
    },
    async [ActionUser.setCurrentUser]({ commit, state }, payload: IUser) {
      commit(ActionUser.setCurrentUser, payload);
    },
    async [ActionUser.setSelectedUser]({ commit, state }, payload: IUser) {
      commit(ActionUser.setSelectedUser, payload);
    }
  },
  getters: {
    currentUser: (state: any) => state.currentUser,
    users: (state: any) => state.users,
    role: (state: any) => state.currentUser.role,
    selectedUser: (state: any) => state.selectedUser
  }
};
