import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "navigation" }
const _hoisted_3 = { class: "content bg--light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (!_ctx.isLoggedIn)
    ? (_openBlock(), _createBlock(_component_Login, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_Navbar),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Menu)
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isShowBreadcrumb)
              ? (_openBlock(), _createBlock(_component_Breadcrumb, { key: 0 }))
              : _createCommentVNode("", true),
            (_openBlock(), _createBlock(_component_RouterView, {
              key: _ctx.$route.fullPath
            }))
          ])
        ])
      ], 64))
}