import { Module } from 'vuex';

import { IContentType } from '@/interfaces/IContentType';
type StateContentType = {
  contentTypes: IContentType[];
  newContentTypes: IContentType[];
  newContentTypeSelected: IContentType | null;
};

export enum MutationsHome {
  SET_CONTENT_TYPES = 'SET_CONTENT_TYPES',
  SET_NEW_CONTENT_TYPES = 'SET_NEW_CONTENT_TYPES'
}
export enum ActionContentType {
  setContentTypes = 'setContentTypes',
  setNewContentTypes = 'setNewContentTypes',
  resetNewContentTypes = 'resetNewContentTypes',
  setNewContentTypeSelected = 'setNewContentTypeSelected',
  addNewContentType = 'addNewContentType',
  editContentType = 'editContentType'
}
export const ModuleContentType: Module<StateContentType, any> = {
  state: {
    contentTypes: [],
    newContentTypes: [],
    newContentTypeSelected: null
  },
  mutations: {
    [ActionContentType.setContentTypes](state: StateContentType, payload: IContentType[]) {
      state.contentTypes = payload;
    },
    [ActionContentType.setNewContentTypes](state: StateContentType, payload: IContentType[]) {
      state.newContentTypes = payload;
    },
    [ActionContentType.resetNewContentTypes](state: StateContentType) {
      state.newContentTypes = [];
    },
    [ActionContentType.setNewContentTypeSelected](state: StateContentType, payload: IContentType) {
      state.newContentTypeSelected = payload;
    },
    [ActionContentType.editContentType](state: StateContentType, payload: IContentType) {
      const index = state.newContentTypes.findIndex((item: IContentType) => item.id === payload.id)
      state.newContentTypes[index] = payload;
    },
    [ActionContentType.addNewContentType](state: StateContentType, payload: IContentType) {
      state.newContentTypes.push({ ...payload, id: state.newContentTypes.length, order: state.newContentTypes.length });
    }
  },
  actions: {
    async [ActionContentType.setContentTypes]({ commit, state }, payload: string) {
      commit(ActionContentType.setContentTypes, payload);
    },
    async [ActionContentType.setNewContentTypes]({ commit, state }, payload: IContentType) {
      commit(ActionContentType.setNewContentTypes, payload);
    },
    async [ActionContentType.resetNewContentTypes]({ commit, state }, payload: string) {
      commit(ActionContentType.resetNewContentTypes, payload);
    },
    async [ActionContentType.setNewContentTypeSelected]({ commit, state }, payload: string) {
      commit(ActionContentType.setNewContentTypeSelected, payload);
    },
    async [ActionContentType.addNewContentType]({ commit, state }, payload: IContentType) {
      commit(ActionContentType.addNewContentType, payload);
    },
    async [ActionContentType.editContentType]({ commit, state }, payload: IContentType) {
      commit(ActionContentType.editContentType, payload);
    }
  },
  getters: {
    contentTypes: (state: any) => state.contentTypes,
    newContentTypes: (state: any) => state.newContentTypes,
    newContentTypeSelected: (state: any) => state.newContentTypeSelected
  }
};
