import { createStore, Store } from 'vuex';
import stateWatcher from './state-watcher';
import cookies from '@/helpers/cookies';
import { ModuleHotspotDetail } from '@/store/module/hotspot-detail';
import { ModuleContentType } from '@/store/module/content-type';
import { ModuleMenuItem } from '@/store/module/menu-item';
import { ModuleUser } from '@/store/module/user';
export const store = createStore({
  state: {
    auth: {
      isLoggedIn: false
    },
    roomSelected: {},
    hotSpotSelected: {},
    hotSpotContentSelected: {},
    menuItemSelected: {},
    menuItemDetailSelected: {},
    singleItemSelected: {},
    navbarLoading: false,
    languages: [],
    breadcrumb: {
      type: ''
    }
  },
  mutations: {
    initStore(state) {
      try {
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');
        if (access || refresh) state.auth.isLoggedIn = true;
      } catch (e) {
        console.log(e);
      }
    },
    login(state) {
      state.auth.isLoggedIn = true;
    },
    logout(state) {
      try {
        cookies.remove('access');
        cookies.remove('refresh');
        state.auth.isLoggedIn = false;
      } catch (e) {
        console.log(e);
      }
    },

    setTokens(state, { access, refresh, expires_in }) {
      try {
        cookies.set('access', access, expires_in);
        cookies.set('refresh', refresh, expires_in);
        state.auth.isLoggedIn = true;
      } catch (e) {
        console.log(e);
      }
    },
    setNavbarLoading(state, payload) {
      state.navbarLoading = payload;
    },
    setListLanguage(state, payload) {
      state.languages = payload;
    },
    setBreadcrumb(state, payload: any) {
      state.breadcrumb = payload;
    },
    setRoomSelected(state, payload) {
      state.roomSelected = payload;
    },
    setMenuItemSelected(state, payload: any) {
      state.menuItemSelected = payload;
    },
    setHotspotContentSelected(state, payload: any) {
      state.hotSpotContentSelected = payload;
    },
    setHotspotSelected(state, payload: any) {
      state.hotSpotSelected = payload;
    },
    setSingleItemSelected(state, payload: any) {
      state.singleItemSelected = payload;
    },
    setMenuItemDetailSelected(state, payload: any) {
      state.menuItemDetailSelected = payload;
    }
  },
  actions: {
    login(state) {
      state.commit('login');
    },
    setToken(state, data) {
      state.commit('setTokens', data);
    },
    setRoomSelected(state, data) {
      state.commit('setRoomSelected', data);
    },
    setNavbarLoading(state, data) {
      state.commit('setNavbarLoading', data);
    },
    setListLanguage(state, data) {
      state.commit('setListLanguage', data);
    },
    setBreadcrumb(state, data) {
      state.commit('setBreadcrumb', data);
    },
    setHotspotSelected(state, data) {
      state.commit('setHotspotSelected', data);
    },
    setHotspotContentSelected(state, data) {
      state.commit('setHotspotContentSelected', data);
    },
    setMenuItemSelected(state, data) {
      state.commit('setMenuItemSelected', data);
    },
    setSingleItemSelected(state, data) {
      state.commit('setSingleItemSelected', data);
    },
    setMenuItemDetailSelected(state, data) {
      state.commit('setMenuItemDetailSelected', data);
    }
  },
  getters: {
    roomSelected: (state: any) => state.roomSelected,
    navbarLoading: (state: any) => state.navbarLoading,
    languages: (state: any) => state.languages,
    breadcrumb: (state: any) => state.breadcrumb,
    hotSpotSelected: (state: any) => state.hotSpotSelected,
    hotSpotContentSelected: (state: any) => state.hotSpotContentSelected,
    menuItemSelected: (state: any) => state.menuItemSelected,
    singleItemSelected: (state: any) => state.singleItemSelected,
    menuItemDetailSelected: (state: any) => state.menuItemDetailSelected
  },
  modules: { ModuleHotspotDetail, ModuleContentType, ModuleMenuItem, ModuleUser },
  plugins: [stateWatcher.watch]
});

export function useStore() {
  return store as Store<any>;
}
