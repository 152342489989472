import $axios from '@/helpers/axios';
import { IUser } from '@/interfaces/IUser';

export const getUserInfo = async () => {
  try {
    let url = `/users/me/`;
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getUserDetailById = async (id: any) => {
  try {
    let url = `/admin/users/${id}/`;
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const getListUser = async () => {
  try {
    let url = `/admin/users/`;
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const createNewUser = async (user: IUser) => {
  try {
    let url = `/admin/users/`;
    let response = await $axios.post(url, user);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateUser = async (user: IUser) => {
  try {
    let url = `/admin/users/${user.id}/`;
    let response = await $axios.patch(url, user);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const deleteUser = async (id: any) => {
  try {
    let url = `/admin/users/${id}/`;
    let response = await $axios.delete(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};
