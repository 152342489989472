
import IconGalleryRoom from '@/components/common/icons/IconGalleryRoom.vue';
import IconSettings from '@/components/common/icons/IconSettings.vue';
import ArrowIcon from '@/components/common/icons/ArrowIcon.vue';
import LinkSection from '@/components/menu/LinkSection.vue';
import IconHome from '@/components/common/icons/IconHome.vue';
import CanonLogo from '@/components/CanonLogo.vue';
import { getListGalleryRoom } from '@/services/api/gallery';
import {defineComponent, ref, reactive, computed, onMounted, onBeforeMount} from 'vue';
import { useStore } from '@/store';
import Spinner from '@/components/common/Spinner.vue';
import {IUser} from "@/interfaces/IUser";
import {getListUser, getUserInfo} from "@/services/api/user";
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'Menu',
  components: {
    CanonLogo,
    IconHome,
    LinkSection,
    ArrowIcon,
    IconSettings,
    IconGalleryRoom,
    Spinner
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const setCurrentUser = (payload: IUser) => store.dispatch('setCurrentUser', payload);
    const currentUser = computed(() => store.getters.currentUser);
    const role = computed(() => store.getters.role);
    const isAdminRole = computed(() => role.value === 'canon_admin');
    const navbarLoading = computed(() => store.getters.navbarLoading);
    let rooms = reactive([]);
    const roomSelected = computed(() => store.getters.roomSelected);
    const token = computed(() => store.state.auth.access);
    const handleSelectRoom = (room: any) => {
      store.dispatch('setRoomSelected', room);
    };

    onMounted(async () => {
        loading.value = true;
        try {
          let response = await getUserInfo();
          await setCurrentUser(response);
        } catch (err: any) {
          console.log('error', err);
        } finally {
          loading.value = false;
        }
    });

    return {
      loading,
      rooms,
      token,
      currentUser,
      isAdminRole,
      handleSelectRoom,
      roomSelected,
      navbarLoading
    };
  },
  async created() {
    this.loading = true;
    try {
      this.rooms = await getListGalleryRoom();
    } catch (err) {
      console.log('err', err);
    } finally {
      this.loading = false;
    }
  }
});
