<template>
  <div class="wrapper">
    <h1 class="title">404</h1>
    <p class="sub-title">Page not found</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style lang="less" scoped>
@import '@/assets/less/colors';

.wrapper {
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  .title,
  .sub-title {
    margin: 0;
  }
  .title {
    color: @color-red;
    margin-right: 0.5rem;
    font-size: 3.5rem;
  }
}
</style>
