import axios from 'axios';
import { VUE_APP_API_URL } from '@/config';
import cookies from '@/helpers/cookies';
import {useStore} from '@/store/index';
import { message } from 'ant-design-vue';

const store = useStore();
export const $axios = axios.create({
  baseURL: VUE_APP_API_URL
});

$axios.interceptors.request.use(
  function(config: any) {
    const access = cookies.get('access');
    if (access) {
      config.headers.authorization = `Bearer ${access}`;
    }
    return config;
  },
  function(error) {
    console.log('request interceptor error', error);
  }
);

let requestQueue: any[] = [];
let isRefreshing = false;
$axios.interceptors.response.use(
  function(res) {
    return res;
  },
  function(err) {
    const {
      config,
      response: { status, data }
    } = err;
    if (status === 403 && Number(data.code) === 1003) {
      store.commit('logout');
      message.error(data.message);
    } else if ((status === 401 && data.code === 1000) || (status === 400 && data.code === 1000)) {
      console.log('Access token invalid');
      const refresh = cookies.get('refresh');

      if (refresh && !isRefreshing) {
        isRefreshing = true;
        refreshToken(refresh)
          .then(refreshed => {
            if (refreshed) {
              isRefreshing = false;
              console.log('tokens refreshed');
              onTokenRefreshed();
            }
            requestQueue = [];
          })
          .catch(error => {
            return Promise.reject(error);
          });
      }

      const request = new Promise(resolve => {
        addToRequestQueue(() => {
          config.headers.authorization = `Bearer ${cookies.get('access')}`;
          resolve(axios(config));
        });
      });
      return request;
    }
    return Promise.reject(data);
  }
);

const addToRequestQueue = (cb: any) => {
  requestQueue.push(cb);
};

const onTokenRefreshed = () => {
  console.log(`retry ${requestQueue.length} queued requests`);
  requestQueue.map(cb => cb());
};

const refreshToken = (refresh: any) =>
  new Promise((resolve, reject) => {
    console.log('refreshing token');
    $axios
      .post(`/token/refresh/`, { refresh })
      .then(res => {
        cookies.remove('access');
        cookies.remove('refresh');
        cookies.set('access', res.data.access, res.data.expires_in);
        cookies.set('refresh', res.data.refresh, res.data.expires_in);
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });

// const refreshToken = async (refresh: string) => {
//   try {
//     let res = await $axios.post(`/token/refresh/`, { refresh });
//     cookies.remove('access');
//     cookies.remove('refresh');
//     cookies.set('access', res.data.access, res.data.expires_in);
//     cookies.set('refresh', res.data.refresh, res.data.expires_in);
//     return true;
//   } catch (err) {
//     console.log('refresh token error', err);
//     return false;
//   }
// };

export default $axios;
