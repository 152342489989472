import $axios from '@/helpers/axios';

export const getHotspotByID = async (hotspotID: string | any) => {
  let url = `/admin/gallery_room/hotspot/${hotspotID}/`;
  try {
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getHotspotListByGalleryId = async (galleryId: string | any) => {
  let url = `/admin/gallery_room/${galleryId}/hotspot/`;
  try {
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateHotspot = async (payload: any) => {
  let url = `/admin/gallery_room/hotspot/${payload.id}/`;
  try {
    let response = await $axios.put(url, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateFlagPublishHotspot = async (payload: any) => {
  let url = `/admin/gallery_room/hotspot/${payload.id}/`;
  try {
    let response = await $axios.patch(url, payload);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteHotspotById = async (id: any) => {
  let url = `/admin/gallery_room/hotspot/${id}/`;
  try {
    let response = await $axios.delete(url);
    return response;
  } catch (err) {
    throw err;
  }
};
