import $axios from '@/helpers/axios';

export const createNewHotspotContentSingleItem = async (hotspotContentId: string | any, payload: any) => {
  let url = `/admin/gallery_room/hotspot/content/${hotspotContentId}/single-item/`;
  try {
    let response = await $axios.post(url, payload);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getDetailSingleItem = async (menuItemID: string | any) => {
  let url = `/admin/gallery_room/hotspot/content/${menuItemID}/single-item/detail/`;
  try {
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getMenuItemDetail = async (id: any) => {
  let url = `/admin/gallery_room/menu-item-detail/${id}/`;
  try {
    let response = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateSingleItem = async (id: any, payload: any) => {
  let url = `/admin/gallery_room/hotspot/content/single-item/${id}/`;
  try {
    let response = await $axios.patch(url, payload);
    return response.data;
  } catch (err) {
    throw err;
  }
};
