<template>
  <div class="wrapper">
    <div class="message">
      <h1 class="title">403</h1>
      <p class="sub-title">Forbidden</p>
    </div>
    <p class="description">You do not have permission to access this page</p>
    <button type="submit" @click="handleLogout" class="btn btn-primary">Please login another account</button>
  </div>
</template>

<script>
import { defineComponent } from 'vue-demi';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Page403',
  setup() {
    const store = useStore();
    const router = useRouter();
    const handleLogout = () => {
      router.push({ name: 'login' });
      store.commit('logout');
    };
    return {
      handleLogout
    };
  }
});
</script>

<style lang="less" scoped>
@import '@/assets/less/colors';

.wrapper {
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    .title,
    .sub-title {
      margin: 0;
    }
    .title {
      color: @color-red;
      margin-right: 0.5rem;
      font-size: 4.5rem;
    }
    .sub-title {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
}
</style>
