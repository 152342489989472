
import IconUser from '@/components/common/icons/IconUser.vue';
import IconLock from '@/components/common/icons/IconLock.vue';
import { defineComponent, ref, reactive, Ref, ReactiveEffect, watch } from 'vue';
import { login } from '@/services/api/login';
import { IMessage } from '@/interfaces/IMessage';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
export default defineComponent({
  name: 'Login',
  components: {
    ForgotPassword,
    IconLock,
    IconUser
  },
  setup(props: any, context: any) {
    const loading: Ref<boolean> = ref(false);
    const showForgotPasswordMessage: Ref<boolean> = ref(false);
    const toggleForgotPasswordMessage = () => showForgotPasswordMessage.value = !showForgotPasswordMessage.value
    const store = useStore();
    const router = useRouter();

    let message: IMessage = reactive({
      loading: null,
      error: null
    });
    let form: any = reactive({
      email: '',
      password: ''
    });

    const submit = async () => {
      loading.value = true;
      message.loading = 'please wait';
      if (!form.email || !form.password) {
        message.error = 'Did you enter your email and password?';
        loading.value = false;
        return false;
      }
      try {
        let response = await login(form);
        store.dispatch('setToken', response);
        router.push({ name: 'home' });
      } catch (err) {
        console.log('er', err);
        message.error = 'Invalid login';
      } finally {
        loading.value = false;
      }
    };

    watch(
      form,
      (val, oldVal) => {
        message.error = null;
      },
      { deep: true }
    );

    return {
      loading,
      message,
      form,
      router,
      showForgotPasswordMessage,
      toggleForgotPasswordMessage,
      submit
    };
  }
});
