import $axios from '@/helpers/axios';

export const getListGalleryRoom = async () => {
  let url = `/admin/gallery_room/`;
  try {
    const response: any = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getGalleryDetailById = async (id: string | any) => {
  let url = `/admin/gallery_room/${id}/`;
  try {
    const response: any = await $axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};
