<template>
  <div class="forgot-password--text">
    <hr>
    <small>
      <p>Forgotten your password? No problem that can happen to anyone.</p>
      Simply send an e-mail to:<br>
      <a :href="`mailto:${mail.to}?cc=${mail.cc}&subject=I forgot my password&body=Please send me a new password`" target="_blank">cmsc-dm-team@medical.canon</a><br>
      and we will send you a new password.
    </small>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  setup() {
    const mail = {
      to: 'cmsc-dm-team@medical.canon',
      cc: 'kyle1.mackenzie@medical.canon',
    }

    return {
      mail
    }
  }
}
</script>

<style lang="less" scoped>
.forgot-password--text {
  text-align: left;

  p {
    cursor: auto;
  }
}
</style>