<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
    <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-5 -3.5)">
      <path
        id="Path_2444"
        data-name="Path 2444"
        d="M20,27.833V26.056A3.528,3.528,0,0,0,16.5,22.5h-7A3.528,3.528,0,0,0,6,26.056v1.778"
        transform="translate(0 -7.333)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_2445"
        data-name="Path 2445"
        d="M19,8.056A3.5,3.5,0,1,1,15.5,4.5,3.528,3.528,0,0,1,19,8.056Z"
        transform="translate(-2.5)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconUser',
  props: {
    color: {
      type: String,
      default: '#666'
    }
  }
};
</script>

<style lang="less" scoped></style>
