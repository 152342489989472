export let settingsRoutes = [
  {
    path: '/settings/user',
    name: 'user-detail',
    props: true,
    component: () => import(/* webpackChunkName: "UserSettings" */ '../views/settings/UserDetail.vue'),
    meta: {
      breadcrumb: {
        name: 'Users',
        type: 'user'
      }
    },
    children: [
      {
        path: 'add',
        name: 'user-add',
        props: true,
        component: () => import(/* webpackChunkName: "UserSettings" */ '../views/settings/UserAdd.vue'),
        meta: {
          breadcrumb: {
            name: 'New User',
            type: 'user'
          }
        }
      },
      {
        path: 'edit/:user_id',
        name: 'user-edit',
        props: true,
        component: () => import(/* webpackChunkName: "UserSettings" */ '../views/settings/UserEdit.vue'),
        meta: {
          breadcrumb: {
            name: 'Edit User',
            type: 'user'
          }
        }
      }
    ]
  }
];
