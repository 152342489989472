<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g id="Icon_feather-info" data-name="Icon feather-info" transform="translate(-2 -2)">
      <path
        id="Path_2433"
        data-name="Path 2433"
        d="M19,11a8,8,0,1,1-8-8,8,8,0,0,1,8,8Z"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_2434"
        data-name="Path 2434"
        d="M18,21.2V18"
        transform="translate(-7 -7)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_2435"
        data-name="Path 2435"
        d="M18,12h0"
        transform="translate(-7 -4.2)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconInfo',
  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
};
</script>

<style lang="less" scoped></style>
