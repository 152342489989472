class CookieService {
  set(key: string, value: string, expires: any) {
    let date = '';
    if (key === 'refresh') {
      date = new Date((expires + 60 * 30 * 4) * 1000).toUTCString();
    } else {
      date = new Date(expires * 1000).toUTCString();
    }
    document.cookie = `${key}=${value}; expires=${date}; path=/; SameSite=Strict; secure=true`;
  }

  get(key: string) {
    const cookie = document.cookie;
    const cookieArray = cookie.split(';');
    const filtered = cookieArray.filter(item => item.includes(key));
    if (filtered.length) {
      return filtered[0].split('=')[1];
    }
  }

  remove(key: string) {
    document.cookie = `${key}=; expires=${new Date(0).toUTCString()}; path=/; SameSite=Strict; secure=true`;
  }
}

export default new CookieService();
