import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ece5944e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickLink && _ctx.handleClickLink(...args))),
      disabled: _ctx.disabled,
      class: _normalizeClass([[{ disabled: _ctx.disabled, 'btn--breadcrumb': _ctx.meta.breadcrumb.type === 'menu-item-detail' }], "btn btn--custom"])
    }, _toDisplayString(_ctx.nameDisplay), 11, _hoisted_1),
    (!_ctx.disabled && _ctx.nameDisplay)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " / "))
      : _createCommentVNode("", true)
  ], 64))
}