
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '@/store';

import BreadcrumbItem from '@/components/breadcrumb/BreadcrumbItem.vue';
export default defineComponent({
  name: 'Breadcrumbs',
  components: { BreadcrumbItem },

  setup(props: any, context: any) {
    const loading = ref(false);
    const route = useRoute();
    const store = useStore();
    const breadcrumb = computed(() => store.getters.breadcrumb);

    const matchedPaths = computed(() => route.matched);

    const crumbs = computed(() => {
      let breadcrumbs = matchedPaths.value.reduce((breadcrumbArray: any[], path: any, idx) => {
        breadcrumbArray.push({
          path: matchedPaths.value[idx].path,
          name: matchedPaths.value[idx].name,
          meta: matchedPaths.value[idx].meta
        });

        return breadcrumbArray;
      }, []);

      return breadcrumbs;
    });

    return {
      breadcrumb,
      crumbs,
      route,
      loading,
      matchedPaths
    };
  }
});
