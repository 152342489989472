
import { defineComponent } from '@vue/runtime-core';
import { ref } from 'vue';

export default defineComponent({
  name: 'LinkSection',
  setup() {
    const open = ref(false);
    return {
      open
    };
  },

  mounted() {
    const linkItems = this.$el.querySelectorAll('.link-item');

    if (linkItems && linkItems.length) {
      linkItems.forEach((item: any) => {
        if (item.classList.contains('router-link-active')) {
          this.open = true;
        }
      });
    }
  },
  updated() {
    const linkItems = this.$el.querySelectorAll('.link-item');
    if (linkItems && linkItems.length) {
      linkItems.forEach((item: any) => {
        if (item.classList.contains('router-link-active')) {
          this.open = true;
        }
      });
    }
  },
  methods: {
    handler: function(e: any) {
      if (e.target.nodeName !== 'A') this.open = !this.open;
    }
  }
});
